import React, { useEffect, useState } from 'react';
import { emptyGUID } from '../../../core/utilities/String';
import { parseQueryString } from '../../../core/utilities/URL';
import MSEmbeddedPublicReport from '../../../report-microstrategy/components/templates/public/MSEmbeddedPublicReport';
import MSEmbeddedPageReport from '../../../report-microstrategy/components/templates/report-only/MSEmbeddedPageReport';
import { defaultReportPage, IReportPage } from '../../../reporting/entities/ReportPage';
import { useGetReportPublicPagePageByOrganization } from '../../../reporting/services/ReportPageService';
import { defaultHeaderText, defaultHeaderTextEmtpy, IHeaderText } from '../../entities/HeaderText';

import { useLocation, useNavigate } from 'react-router';
import { MenuEntity } from '../../../core/components/menu/entities/MenuEntity';
import { IOrganizationalData } from '../../../reporting/entities/OrganizationalData';
import { useGetOrganizationalData } from '../../../reporting/services/ReportService';
import { IRoute } from '../../entities/Route';
import { getSiteSetting, SiteSetting } from '../../entities/SiteSettings';
import Header from '../components/Header';
import { ICustomRouteProperties } from '../entities/CustomRouteProperties';

interface IComponentInfo {
    toggleCollapse: () => void;
    menuCollapsed: boolean;
    siteMenu: MenuEntity[];
    isLoadingMenu: boolean;
    routes: IRoute[];
    routeProperties: ICustomRouteProperties;
    setRouteProperties: (i: ICustomRouteProperties) => void
}

const ReportingLayout: React.FunctionComponent<IComponentInfo> = ({ siteMenu, toggleCollapse, menuCollapsed, routes, routeProperties, setRouteProperties }) => {
    const params: any = parseQueryString();
    const { service: pageService, setItem } = useGetReportPublicPagePageByOrganization();
    const { service: headerService, setOrgCode } = useGetOrganizationalData();

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageId] = useState(params['pid'] || '');
    const [organization] = useState(params['organization'] || '');
    const [headerText, setHeaderText] = useState<IHeaderText>(defaultHeaderText)

    const [headerWidth, setHeaderWidth] = useState<number>(parseInt(getSiteSetting(SiteSetting.ReportContentWidth)));

    const { pathname } = useLocation();
    const [pageName, setPageName] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        //cleaning the menu
        localStorage.setItem('activeLabels', JSON.stringify([]));
    }, [])

    useEffect(() => {
        const page = routes.filter(rt => rt.path === pathname);
        if (pathname === '/home' || pathname === '/') {
            setHeaderText({
                ...defaultHeaderTextEmtpy,
                left: 'Welcome to the Kentucky School Report Card',
                middle: routeProperties.academicYear
            });
        } else {
            setHeaderText({
                ...defaultHeaderTextEmtpy,
                middle: routeProperties.academicYear,
                left: 'Kentucky School Report Card'
            });
        }
        setPageName(page[0]?.name ?? "")
        // eslint-disable-next-line
    }, [navigate, pageName, pathname, routes])

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        setOrgCode(organization);
        // eslint-disable-next-line
    }, [organization]);

    useEffect(() => {
        if (headerService.result) {
            const c: IOrganizationalData = headerService.result;
            const school: string = c.school;
            const district: string = c.district;
            setHeaderText({
                ...headerText,
                left: (school === 'All Schools' || school === '') ? (district === 'All Districts' ? 'Kentucky' : district) : school,
                middle: routeProperties.academicYear,
                contact: c.contactName,
                email: c.contactEmail,
                title: c.contactTitle
            });
        }
        // eslint-disable-next-line
    }, [headerService]);

    useEffect(() => {
        if (siteMenu.length > 0) {
            if (isSingleReport()) {
                setHeaderText({
                    ...defaultHeaderTextEmtpy,
                    left: 'Kentucky School Report Card',
                    middle: routeProperties.academicYear
                });
            } else {
                setItem({ organization, id: pageId });
            }
        }
        // eslint-disable-next-line
    }, [siteMenu]);

    useEffect(() => {
        setItem({ organization, id: pageId });
        // eslint-disable-next-line
    }, [pageId]);

    const isSingleReport = (): boolean => {
        switch (window.location.pathname) {
            case '/embedded-report-public':
                return true;
            default:
                return false;
        }
    };
    return (
        <>
            <Header
                collapsed={menuCollapsed}
                toggleCollapse={toggleCollapse}
                headerText={headerText}
                headerWidth={headerWidth}
                layout={1}
                routeProperties={routeProperties}
                setRouteProperties={setRouteProperties}
                reportingPage={true}
            />
            <div className='layout-definition'>
                <div className='body-container' id="main-content" style={{ width: '100%' }}>
                    {!isSingleReport() && !emptyGUID(page.id) && (
                        <MSEmbeddedPublicReport
                            pageId={page.id}
                            organization={organization}
                            headerText={headerText}
                            setHeaderWidth={setHeaderWidth}
                            routeProperties={routeProperties}
                            setRouteProperties={setRouteProperties}
                            siteMenu={siteMenu}
                            menuCollapsed={menuCollapsed}
                            isLoadingMenu
                        />
                    )}
                    {isSingleReport() && (
                        <MSEmbeddedPageReport isPublic={emptyGUID(routeProperties.user.id)} />
                    )}
                </div>
            </div>
        </>
    );
};

export default ReportingLayout;